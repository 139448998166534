import React from "react";
import PropTypes from "prop-types";
import BlogPostsPageTemplate from "../templates/blog-posts-page-template";
import { Layout } from "../components/common";

const PopularBlogPosts = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;

    return (
        <Layout>
            <BlogPostsPageTemplate
                title={"Popular Posts"}
                posts={posts}
                pageContext={pageContext}
                location={location}
            />
        </Layout>
    );
};

PopularBlogPosts.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default PopularBlogPosts;

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        allGhostPost(
            skip: $skip
            limit: $limit
            sort: { order: DESC, fields: [updated_at] }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
